/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/reset.css"
import "./src/layout.css"

import "intersection-observer"
import objectFitImages from "object-fit-images"

objectFitImages()
